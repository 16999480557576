<template>
  <div class="page">
    <div class="pageFucView">
      <div>
        <el-button style="margin-top: 10px" icon="el-icon-plus" type="primary" @click="handleEdit" size="small">添加批发
        </el-button>
      </div>
      <div style="text-align: right">
        <el-form :inline="true" :model="queryForm" style="margin-top: 5px" @submit.native.prevent>
          <el-form-item label="商品名称:">
            <el-input
                v-model.trim="queryForm.name"
                clearable
                placeholder="请输入商品名称"
                size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>


    <el-table
        :data="list">
      <el-table-column align="left" label="商品" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.sku_info.name }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          :formatter="formatterState"
          prop="state"
          label="状态">
      </el-table-column>
      <el-table-column
          align="center"
          label="批发价格区间"
      width="220px">
        <template slot-scope="scope">
          <span v-for="item in scope.row.ladder_price">
            {{item.number}}:{{item.price}}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
<!--          <el-button type="text" @click="handleEdit(row)">编辑</el-button>-->
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="queryForm.pageSize" :current-page.sync="queryForm.page" :total="count" @current-change="handleCurrentChange" />
    </div>
    <Edit ref="Edit"/>
  </div>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiDelWholesaleList, apiGetWholesaleList} from "@/request/api";

export default {
  name: "wholesale",
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      activeName: '1',
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
      },
      current: 0,
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    //新增、编辑
    handleEdit() {
        this.$router.push({
          path: '/wholesale/wholesale',
        })
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDelWholesaleList({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetWholesaleList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state === 0 ? '销售结束'
          : row.state == 1 ? '销售中'
              : '暂无'
    },
  }
}

</script>
<style>
.page {
  background-color: #FFF;
  padding: 20px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.commodity_pic {
  width: 60px;
  height: 60px;
}
</style>
